import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { collection, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCqG8vD1l754bK2R7PmXrgYyKl2mCe8ZlQ",
  authDomain: "daring-calling-280401.firebaseapp.com",
  databaseURL: "https://daring-calling-280401.firebaseio.com",
  projectId: "daring-calling-280401",
  storageBucket: "daring-calling-280401.appspot.com",
  messagingSenderId: "771050794368",
  appId: "1:771050794368:web:086c154476f4f129b46565",
  measurementId: "G-9RPSYL8SY8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

// Collection references (Firestore v9+ syntax)
const usersCollection = collection(db, "users");
const huntsCollection = collection(db, "hunts");

// Export the services
export { auth, db, huntsCollection, storage, usersCollection };

